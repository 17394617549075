(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/get-cms-config/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/lb-utils/get-cms-config/assets/javascripts/utils.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let sessionMockData = {};
const configs = {
  competitions: {},
  product: {},
  services: {},
  urlmappings: {}
};
const logger = svs.isServer ? require('trinidad-core').core.logger('lb-utils: brand-mapping') : svs.core.log.getLogger('lb-utils: brand-mapping');
if (svs.isServer) {
  configs.competitions = require('../../../../../site-settings/competition/config-schema.json');
  configs.product = require('../../../../../site-settings/product/config-schema.json');
  configs.services = require('../../../../../site-settings/services/config-schema.json');
  configs.urlmappings = require('../../../../../site-settings/urlmappings/config-schema.json');
}
const MountOptions = {
  COMPETITIONS: 'competitions',
  PRODUCT: 'product',
  SERVICES: 'services',
  URL_MAPPINGS: 'urlmappings'
};
const AllScopes = Object.values(MountOptions);
const deepMerge = (target, source) => {
  const clone = _objectSpread({}, target);
  Object.keys(source).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (Object.prototype.hasOwnProperty.call(clone, key)) {
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
          clone[key] = deepMerge(clone[key], source[key]);
        } else {
          clone[key] = source[key];
        }
      }
    } else {
      clone[key] = source[key];
    }
  });
  return clone;
};
const getServerConfig = () => require('trinidad-core').core.config.options.cms;
const filterCmsByScope = (scope, data) => {
  const keys = Object.keys(configs[scope].properties);
  return keys.reduce((acc, key) => {
    if (!Object.prototype.hasOwnProperty.call(data, key)) {
      return acc;
    }
    return _objectSpread(_objectSpread({}, acc), {}, {
      [key]: data[key]
    });
  }, {});
};
const getResModelForScope = (scope, data) => AllScopes.includes(scope) ? filterCmsByScope(scope, data) : {};
const buildCmsModel = function () {
  let scopes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return scopes.reduce((acc, scope) => _objectSpread(_objectSpread({}, acc), getResModelForScope(scope, data)), {});
};
const getMockConfigForScope = function () {
  let scopes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return scopes.reduce((acc, scope) => {
    if (!Object.prototype.hasOwnProperty.call(data, scope)) {
      return acc;
    }
    if (Array.isArray(data[scope])) {
      return _objectSpread(_objectSpread({}, acc), {}, {
        [scope]: data[scope]
      });
    }
    return _objectSpread(_objectSpread({}, acc), data[scope]);
  }, {});
};

const mountCmsConfig = scopes => async function (req, res) {
  var _req$session;
  let next = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
  logger.debug("** mountCmsConfig for ".concat(JSON.stringify(scopes), " **"));
  const cmsToResModel = buildCmsModel(scopes, getServerConfig());
  sessionMockData = req.session.mock;

  res.addModels([{
    objectName: 'mock',
    name: 'config',
    model: ((_req$session = req.session) === null || _req$session === void 0 ? void 0 : _req$session.mock) || {}
  }, {
    objectName: 'cmsData',
    name: 'config',
    model: deepMerge(cmsToResModel, getMockConfigForScope(scopes, sessionMockData))
  }, {
    objectName: 'cmsDataFull',
    name: 'config',
    model: getServerConfig()
  }]);
  next();
};

const getMockConfig = svs.isServer ? (scopes, session) => {
  const sessionData = session ? session.mock : sessionMockData;
  return getMockConfigForScope(scopes, sessionData);
} : scopes => {
  var _svs$mock;
  const mockData = ((_svs$mock = svs.mock) === null || _svs$mock === void 0 || (_svs$mock = _svs$mock.data) === null || _svs$mock === void 0 ? void 0 : _svs$mock.config) || {};
  return getMockConfigForScope(scopes, mockData);
};

const getCmsConfig = svs.isServer ? session => {
  const serverConfig = getServerConfig();
  const builtCmsModel = buildCmsModel(AllScopes, serverConfig);
  const mockConfig = getMockConfig(AllScopes, session);
  const mergedConfig = deepMerge(builtCmsModel, mockConfig);
  return mergedConfig;
} : () => {
  var _svs$cmsData;
  const serverConfig = ((_svs$cmsData = svs.cmsData) === null || _svs$cmsData === void 0 || (_svs$cmsData = _svs$cmsData.data) === null || _svs$cmsData === void 0 ? void 0 : _svs$cmsData.config) || {};
  const mockConfig = getMockConfig(AllScopes);
  const mergedConfig = deepMerge(serverConfig, mockConfig);
  return mergedConfig;
};
const toExport = {
  getCmsConfig
};
if (svs.isServer) {
  module.exports = _objectSpread(_objectSpread({}, toExport), {}, {
    getServerConfig,
    mountCmsConfig,
    AllScopes,
    MountOptions,
    buildCmsModel
  });
} else {
  setGlobal('svs.components.lbUtils.getCmsConfig.utils', toExport);
}

 })(window);